<template>
  <v-app-bar
    app
    color="white"
    dark
    elevate-on-scroll>
    <v-container fluid class="mx-auto py-0">
      <v-row align="center">
        <v-img
          :src="require('@/assets/EFAM.png')"
          :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : 'mr-9 ml-15'"
          class=""
          contain
          height="120"
          width="120"
          max-width="120"
          @click="$vuetify.goTo(0)"
        />
        <v-btn
          v-bind="link"
          class="hidden-sm-and-down font-weight-bold"
          text
          color="black"
          @click="$vuetify.goTo('#accueil')"
        >
          Accueil
        </v-btn>
        <v-btn
          v-bind="link"
          class="hidden-sm-and-down font-weight-bold"
          text
          color="black"
          @click="$vuetify.goTo('#nos-valeurs')"
        >
          Nos valeurs
        </v-btn>
        <v-btn
          v-bind="link"
          class="hidden-sm-and-down font-weight-bold"
          text
          color="black"
          @click="$vuetify.goTo('#pedago')"
        >
          Nos moyens
        </v-btn>
        <v-btn
          v-bind="link"
          class="hidden-sm-and-down font-weight-bold"
          text
          color="black"
          @click="$vuetify.goTo('#formations')"
        >
          Nos formations
        </v-btn>
        <v-btn
          v-bind="link"
          class="hidden-sm-and-down font-weight-bold"
          text
          color="black"
         
          @click="$vuetify.goTo('#contact')"
        >
          Notre Localisation
        </v-btn>
        <v-btn
          v-bind="link"
          class="hidden-sm-and-down font-weight-bold"
          text
          color="black"
         
          @click="$vuetify.goTo('#footer')"
        >
          Contact
        </v-btn>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import {
    mapGetters,
    mapMutations,
  } from 'vuex';
export default {
    name: 'Navbar',

    

   data: () => ({
    //
    drawer: false,
      group: null,
  }),
 watch: {
      group () {
        this.drawer = false
      },
    },
    computed: {
    
      ...mapGetters(['links']),
    },
    methods: {
      ...mapMutations(['toggleDrawer']),
      onClick (e, item) {
        e.stopPropagation()

        if (item.to || !item.href) return

        this.$vuetify.goTo(item.href.endsWith('!') ? 0 : item.href)
      },
    },
}

</script>